@import url("https://fonts.googleapis.com/css?family=Noto+Sans&display=swap");
@import url("https://fonts.googleapis.com/css?family=Liu+Jian+Mao+Cao&display=swap");

:root {
  --primaryColor: rgb(122, 227, 166);
  --SecondaryColor: rgb(134, 206, 255);
}

html {
  height: 100%;
}

body {
  font-size: 16px;
  font-family: "Noto Sans", sans-serif;
  display: grid;
  overflow-x: hidden;
  justify-items: center;
  background-image: linear-gradient(135deg, var(--primaryColor), var(--SecondaryColor));
}

#header {
  font-size: 3rem;
}

#mainLogo {
  height: 8rem;
  padding-top: 3rem;
}

.note {
  font-family: "Liu Jian Mao Cao", cursive;
  font-size: 2rem;
}

#napkinContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

.napkin {
  display: grid;
  justify-items: center;
  align-content: center;
  padding: 1rem;
  height: 10rem;
  width: 10rem;
  background-color: white;
  border-radius: 1rem;
  font-weight: bold;
  box-shadow: 3px 3px 10px grey;
}

/* ========== */

#napkinCoronavirus {
  position: relative;
  color: white;
}

#napkinCoronavirusBackground {
  position: absolute;
  background-image: url("./assets/virus.jpg");
  background-size: cover;
  border-radius: 1rem;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  opacity: 0.3;
}

#napkinCoronavirusBackground:hover {
  box-shadow: 8px 8px 18px black;
}

#napkinCoronavirusBackground:active {
  box-shadow: 2px 2px 5px black;
}

#napkinCoronavirusText {
  color: black;
  text-align: center;
}

/* ========== */

#napkinWhereDidWeGo {
  position: relative;
  color: white;
}

#napkinWhereDidWeGoBackground {
  position: absolute;
  background-image: url("./assets/whereDidWeGo.jpg");
  background-size: cover;
  border-radius: 1rem;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  opacity: 0.2;
}

#napkinWhereDidWeGoBackground:hover {
  box-shadow: 8px 8px 18px black;
}

#napkinWhereDidWeGoBackground:active {
  box-shadow: 2px 2px 5px black;
}

#napkinWhereDidWeGoText {
  color: black;
  text-align: center;
}

/* ========== */

#napkinValuesExercise {
  position: relative;
  color: white;
}

#napkinValuesExerciseBackground {
  position: absolute;
  background-image: url("./assets/valuesExercise.jpg");
  background-size: cover;
  border-radius: 1rem;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  opacity: 0.2;
}

#napkinValuesExerciseBackground:hover {
  box-shadow: 8px 8px 18px black;
}

#napkinValuesExerciseBackground:active {
  box-shadow: 2px 2px 5px black;
}

#napkinValuesExerciseText {
  color: black;
  text-align: center;
}

/* ========== */

#napkinTempestNavMagic {
  position: relative;
  color: white;
}

#napkinTempestNavMagicBackground {
  position: absolute;
  background-image: url("./assets/navPlanning.jpg");
  /* background-image: url("./assets/valuesExercise.jpg"); */
  background-size: cover;
  border-radius: 1rem;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  opacity: 0.2;
}

#napkinTempestNavMagicBackground:hover {
  box-shadow: 8px 8px 18px black;
}

#napkinTempestNavMagicBackground:active {
  box-shadow: 2px 2px 5px black;
}

#napkinTempestNavMagicText {
  color: black;
  text-align: center;
}

/* ========== */

#signature {
  position: absolute;
  bottom: 2rem;
  font-size: 1.5rem;
}
